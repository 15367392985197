import React from "react";
import "./AboutHome.css";
import Fade from "react-reveal/Fade";

const MissionHome = () => {
  return (
    <>
      <div className="mission-home">
        <div className="mission-home-content container">
          <Fade top>
            <h1>OUR MISSION</h1>
          </Fade>
          <Fade left>
            <p>
            At 9 Silver, we are dedicated to transforming healthcare recruitment by connecting top healthcare executives, clinical leaders, and specialized medical professionals with leading hospitals, health systems, and medical organizations. Our mission is to provide strategic talent acquisition solutions that drive healthcare leadership, workforce excellence, and patient-centered care. Through executive search expertise, industry insights, and data-driven recruitment, we help organizations build high-performing teams that shape the future of healthcare.
            </p>
          </Fade>
          <Fade right>
            <h2>"Your Future Begins Here..."</h2>
          </Fade>
        </div>
      </div>
    </>
  );
};

export default MissionHome;
