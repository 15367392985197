import React from 'react'
import Fade from 'react-reveal/Fade';

import './Section3.css'

const Section3 = () => {
  return (
    <>
      
      <div className='section3 '>
        <Fade bottom>
            <div className='section3-content container'>
                <h1>CURRENT JOB OPPORTUNITIES </h1><br/>
                <p>Our goal is to be the leading partner that clients and candidates always choose to connect with. To connect great organizations with even greater people. We strive to deliver what we promise, first time every time
                </p>
            </div>
        </Fade>

</div>



    </>
  )
}

export default Section3
