import React from "react";
import { Link as LinkRoll } from "react-scroll";
import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';

import "./Hero.css";

const Hero = () => {
  return (
    <>
<figure>

	<div class="imagesContainer">

  <div className="hero-caption">
    <div>

    <Fade bottom>
      <h1>9 Silver</h1>

    <h2>Executive Search Firm Specializing in Healthcare Recruitment.</h2>

    <LinkRoll
                activeClass="active"
                to="top"
                spy={true}
                smooth={true}
                duration={500}
              >
          <Link to="/contact">
            <div className="hero-btn">
                <button>Call Us</button>
            </div>
          </Link>
      </LinkRoll>
      </Fade>

  </div>

  </div>


		<div class="imageDiv image1">
    </div>
		<div class="imageDiv image2 fadeInClass">
    </div>


	</div>
</figure>
    </>
  );
};

export default Hero;
