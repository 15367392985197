import React from "react";
import './NathanHero.css'
import {Helmet} from "react-helmet";



const NathanHero = () => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Nathan Young - 9 Silver</title>
                <link rel="canonical" href="https://9silver.org/nathan-young" />
            </Helmet>
            <div className='nathan-hero'>
                        <div className="nathan-hero-content">

                        </div>
                    </div>
            </>
    )

    }
export default NathanHero
