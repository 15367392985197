import React, { useRef, useState } from "react";
import { BsFillPinMapFill } from "react-icons/bs";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import emailjs from "emailjs-com";
import "./ContactForm.css";

const ContactForm = () => {
  const form = useRef();
  const [buttonText, setButtonText] = useState("Submit");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_bdfl0ie", "template_i8qb2st", form.current, "CLcHWAKSemVMd1_sU")
      .then(
        (result) => {
          console.log(result.text);
          setButtonText("Message Sent");
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <section className="contact-container">
      <Fade left>
        <div className="contact-cards-container">
          <div className="get_form_inner2">
            <h2>CALL US</h2>
            <p>
              <AiOutlinePhone />
              <a href="tel:3236433171"> +1 (323) 643-3171</a>
            </p>
          </div>

          <div className="get_form_inner2">
            <h2>EMAIL</h2>
            <p>
              <AiOutlineMail /> info@9silver.biz
            </p>
            <br />
          </div>

          <div className="get_form_inner2">
            <h2>LOCATION</h2>
            <p>
              <BsFillPinMapFill /> 1800 Vine Street, Los Angeles CA 90028
            </p>
          </div>
        </div>
      </Fade>

      <div className="get_form_inner">
        <div className="get_form_inner_text">
          <h3>Get In Touch</h3>
          <p>
            <i>We'll keep your contact information strictly confidential.</i>
          </p>
          <br />
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <div className="inputs">
            <input type="text" name="name" placeholder="Your Name" pattern="[A-Za-z ]+" title="Only letters allowed" required />
            <br />
            <input type="email" name="email" placeholder="Your Email" required />
            <br />
            <input type="tel" name="phone" placeholder="Your Phone" pattern="[0-9]{10,15}" title="Enter a valid phone number" required />
            <br />
            <input type="text" name="subject" placeholder="Subject" required />
            <br />
          </div>
          <div>
            <textarea name="message" placeholder="How can we help?" cols="50" rows="8" required></textarea>
            <input type="submit" value={buttonText} disabled={buttonText === "Message Sent"} />
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
