import React from 'react'
import Fade from 'react-reveal/Fade';
import {Helmet} from "react-helmet";

import './About.css'



const About = () => {
    return (

<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>About Us - 9 Silver</title>
            <link rel="canonical" href="https://9silver.org/about" />
        </Helmet>
        <div className='mission main-menu'>
                <div className="mission-content">
                    <Fade top>
                    <h1>ABOUT US</h1>
                    </Fade>
                </div>
            </div>
</>
    )
}

export default About
