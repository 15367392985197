import React from "react";
import "./AboutSection.css";
import { Link } from 'react-router-dom';
import { Link as LinkRoll } from "react-scroll";
import Contact2 from "../contact/Contact2";
import MissionSection1 from "../../assets/missionSection1.webp";


const AboutSection = () => {
  return (
    <>
      <div className="about-section sectionbg">
        <div className="about-content container">
          <div className="about-flex">
            <div className="about-left">
              <h1>ABOUT US</h1>
              <p>
              Our mission at 9 Silver is to connect employees that are talented, motivated, and driven to excellence with great businesses by opening up opportunities for both. Our goals are to provide high-quality outsourced employment, human resource services, and to empower business owners and employees to be their best. 
              
              <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                <Link to='/nathan-young'>
                  <span> Nathan Young, </span>
                </Link>
              </LinkRoll>
              
              with his diverse experience and knowledge, plays a vital role in fulfilling our commitment to quality and excellence.
              </p>
            </div>

            <div className="about-right">
              <img src={MissionSection1} alt="Therapy Session" loading="lazy" />
            </div>
          </div>
        </div>
      </div>

      <Contact2 />
    </>
  );
};

export default AboutSection;
