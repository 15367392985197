import React from 'react'
import Footer from '../components/footer/Footer'
import NathanHero from '../components/Nathan/NathanHero'
import NathanBio from '../components/Nathan/NathanBio'
import Navbar from '../components/navbar/Navbar'

const NathanPage = () => {
    return (
        <>
            <Navbar/>
           <NathanHero />
           <NathanBio />
           <Footer />
        </>
    )
}

export default NathanPage