import React from 'react'
import './NathanBio.css'



const NathanBio = () => {
  return (
    <>
        <div className='nathan-bio'>
          <div className="nathan-bio-content container">
            <h1>Nathan Young</h1>
            <p>
            Under the leadership of Nathan Young, 9 Silver has experienced significant growth and expansion to better serve the rapidly expanding healthcare industry. Thanks to his dedication to those in need, he has helped 9 Silver to fulfill their core values of integrity, excellence, and personalized service.
            <br /><br />


            9 Silver aims to facilitate connections between talented, motivated, and driven employees and exceptional businesses by creating opportunities for both parties. Our objectives include delivering top-tier outsourced employment and human resource services, as well as empowering business owners and employees to achieve their full potential.
            <br /><br />


            With a background and experience that spans decades and continents, Nathan Young exemplifies a life devoted to service in both military and civilian sectors. His significant influence on the healthcare industry is undeniable.
            <br /><br />

            Nathan Young was in college during the most recent Israel-Lebanon war. He made the bold decision to fly to Israel, where he dedicated several months to learning Hebrew before enlisting as a combat soldier in the Israel Defense Forces for a two-year service. This commitment came after receiving an honorable discharge from the Coast Guard Academy. Nathan's unwavering dedication to serving his country is truly commendable.
            <br /><br />

            Upon returning to the United States, Nathan successfully managed a 77-room Airbnb/hotel located near Beverly Hills for a span of 24 years, from 1996 to 2020. Initially, the establishment was a dilapidated retirement home, but under Nathan's guidance, it was transformed into a sanctuary for the marginalized. It was here that Nathan embarked on his unofficial mission of assisting individuals grappling with addiction and homelessness.
            <br /><br />

            His hotel served as a safe haven for those who had been removed from other sober living facilities, as well as for ex-convicts and the homeless. Nathan not only provided employment opportunities for these individuals but also offered unwavering support, creating a community centered around recovery and second chances.
            <br /><br />

            Motivated by a strong desire to create a more structured impact on the world, Nathan established two sober living homes. These homes served as an extension of his dedication to aiding in addiction recovery, offering not only shelter but also covering treatment expenses for numerous individuals. Due to inconsistent payments from treatment centers, the operation essentially functioned as a charity. This endeavor was profoundly personal for Nathan, influenced by the tragic overdose and passing of his girlfriend. This heartbreaking event only strengthened his determination to combat addiction.
            <br /><br />

            Nathan Young's personalized approach to treatment services, with one dedicated employee for each client, set him apart in providing exceptional care. The individualized attention and support from his team of employees ensured that each client received the best possible treatment. This tailored approach proved to be highly effective in helping individuals achieve sobriety.
            <br /><br />

            By prioritizing humanity in his business practices, Nathan has not only cultivated a thriving enterprise but has also made a profound impact on the lives of those most vulnerable. His narrative serves as a guiding light for individuals in the healthcare field, demonstrating that through compassion, perseverance, and ethical conduct, one can truly make a meaningful and beneficial contribution to society.
            <br /><br />
            These principles have guided Nathan Young in shaping and expanding 9 Silver into a leading provider of healthcare staffing services.
            </p>
            <p>

            </p>
          </div>
        </div>
    </>
  )
}

export default NathanBio