import React from 'react'

import AMFT from '../../assets/amft.jpg'
import CaseManager from '../../assets/case-manager.jpg'
import BehavioralTech from '../../assets/behavioral-tech.jpg'

import { Link as LinkRoll } from "react-scroll";
import { Link } from 'react-router-dom';



import './Section4.css'

const Section4 = () => {
  return (
    <>
     <div class="section4">
        <div class="section4-inner">
          <h1>Immediately hiring for...</h1>
            <div class="border"></div>
      
              <div class="row">

                <div class="column">
                  <div class="section4-box">
                        <img src={AMFT} alt="Mental Health" loading="lazy"/>
                      <div class="name">Remote California AMFT</div>
                        <p>
                        Build your career in telehealth counseling with flexible work, clinical supervision, and professional growth opportunities.</p>
                      </div>

                      <div className='section4-btn'>
                      <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/contact">
                          <button>Call Us</button>
                        </Link>
                      </LinkRoll>
                      </div>

                  </div>
        
        <div class="column">
          <div class="section4-box">
          <img src={CaseManager} alt="Mental Health" loading="lazy"/>
            <div class="name">Remote Case Managers</div>
            <p>Advance your career in telehealth case management with flexible remote work, client advocacy, care coordination, and professional growth opportunities.</p>
          </div>
                      <div className='section4-btn'>
                      <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/contact">
                          <button>Call Us</button>
                        </Link>
                      </LinkRoll>
                      </div>
        </div>
        
        <div class="column">
          <div class="section4-box">
          <img src={BehavioralTech} alt="Mental Health" loading="lazy"/>
            <div class="name">Remote Behavioral Technician</div>
            <p>Join a growing field in telehealth behavioral therapy with flexible remote work, applied behavior analysis (ABA), client-centered support, and career advancement opportunities.</p>
          </div>
                      <div className='section4-btn'>
                      <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                        <Link to="/contact">
                          <button>Call Us</button>
                        </Link>
                      </LinkRoll>
                      </div>
        </div>
      </div>
    </div>
  </div>

    </>
  )
}

export default Section4
